import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import DiscussionCard from '@/views/Discussions/Components/DiscussionCard/DiscussionCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
let DiscussionsTopicSearch = class DiscussionsTopicSearch extends Vue {
    constructor() {
        super(...arguments);
        this.filteredTopic = null;
        this.loading = true;
        this.coverImageDesc = "";
        this.searchTimeout = null;
    }
    async onTopicFilterChange(newVal) {
        this.loading = true;
        // Set the filter ID and reset page
        this.filteredTopic = newVal === '' ? null : newVal;
        await this.$store.dispatch('discussions/setFilter', newVal === '' ? null : newVal);
        if (this.$route.query.label !== newVal) {
            await this.$store.dispatch('discussions/setPage', 1);
        }
        else {
            await this.$store.dispatch('discussions/setPage', this.$route.query.page);
        }
        // Call the page load
        await this.$store.dispatch('discussions/getDiscussions');
        this.loading = false;
    }
    created() {
        if (history.state.label && history.state.page) {
            if (this.$route.fullPath === `/discussions?label=${history.state.label}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/discussions?label=${history.state.label}&page=${history.state.page}` });
            }
        }
    }
    async mounted() {
        await this.resetDiscussionValues();
        if (this.$route.query.label) {
            await this.$store.dispatch('discussions/setFilter', this.$route.query.label);
            this.filteredTopic = this.$store.getters['discussions/getFilter'];
        }
        if (this.$route.query.page) {
            await this.$store.dispatch('discussions/setPage', this.$route.query.page);
        }
        await this.$store.commit('discussions/SET_ONLY_FAVOURITES', false);
        await this.$store.dispatch('discussions/getDiscussions');
        await this.$store.dispatch('label/getAllLabels');
        this.loading = false;
    }
    get sortedLabels() {
        let sortedLabels = this.labels;
        sortedLabels.sort((a, b) => {
            let lowerCaseA = a.name.toLowerCase();
            let lowerCaseB = b.name.toLowerCase();
            if (lowerCaseA < lowerCaseB) {
                return -1;
            }
            if (lowerCaseA > lowerCaseB) {
                return 1;
            }
            return 0;
        });
        return sortedLabels;
    }
    get filteredTopicName() {
        let labelName = null;
        this.labels.forEach((label) => {
            if (label.id === this.filteredTopic) {
                labelName = label.name;
            }
        });
        return labelName;
    }
    async beforeDestroy() {
        await this.resetDiscussionValues();
    }
    async resetDiscussionValues() {
        this.filteredTopic = null;
        await this.$store.dispatch('discussions/setFilter', null);
        await this.$store.dispatch('discussions/setPage', 1);
    }
    async goToDiscussionsPage() {
        if (this.filteredTopic != null) {
            this.$router.push({ path: `/discussions?label=${this.filteredTopic}` });
        }
        else {
            this.$router.push({ path: `/discussions` });
        }
    }
    async goToStories() {
        if (this.filteredTopic != null) {
            this.$router.push({ path: `/stories/topic-search?label=${this.filteredTopic}` });
        }
        else {
            this.$router.push({ path: `/stories/topic-search` });
        }
    }
    async goToProjects() {
        if (this.filteredTopic != null) {
            this.$router.push({ path: `/projects/topic-search?label=${this.filteredTopic}` });
        }
        else {
            this.$router.push({ path: `/projects/topic-search` });
        }
    }
    handleClickEvent(e) {
        this.filteredTopic = e.id;
    }
};
__decorate([
    Getter('getDiscussions', { namespace: 'discussions' })
], DiscussionsTopicSearch.prototype, "discussions", void 0);
__decorate([
    Getter('getLabels', { namespace: 'label' })
], DiscussionsTopicSearch.prototype, "labels", void 0);
__decorate([
    Watch('filteredTopic')
], DiscussionsTopicSearch.prototype, "onTopicFilterChange", null);
DiscussionsTopicSearch = __decorate([
    Component({
        components: {
            Btn,
            DiscussionCard,
            TextInput,
            CoverImage,
            SelectInput,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | discussions topic search results',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic discussions topic search results',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/discussions/topic-search` }],
            };
        },
    })
], DiscussionsTopicSearch);
export default DiscussionsTopicSearch;
